<template>
  <div class="capa">
    <img class="logorabi" alt="logo Rabi" src="../assets/logo_rabi.jpg" />
    <MsgInicial msg="SEJA MUITO BEM VINDO A REDE RABI DE POSTOS!" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MsgInicial from "../components/MsgInicial.vue";

@Options({
  components: {
    MsgInicial,
  },
})
export default class MsgInicialE extends Vue {}
</script>

<style lang="scss" scoped>
.capa {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90vh;
  min-height: 60vh;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #e5e0dd;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
}
.logorabi {
  width: 300px;
  height: 270px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 60px;
}

h3 {
  font-size: 1.5rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: rgb(144, 105, 105);
  text-shadow: 0 0 1px #953636, 0 0 1px #deaa1a;
}
</style>
