<template>
  <div class="footer">
    <h3>Venha até os nossos postos e confira o atendimento!</h3>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  display: flex;
  //position: relative; //rola junto com a pagina
  //position: fixed top; //fixo
  //position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin: auto;
  background-color: rgb(190, 188, 186);
  border-top: 6px solid rgb(104, 70, 6);
}

h3 {
  font-size: 1.5rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: rgb(144, 105, 105);
  text-shadow: 0 0 1px #953636, 0 0 1px #deaa1a;
}
</style>
