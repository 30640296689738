<template>
  <div>
    <HeaderView />
    <nav>
      <router-link to="/">Página de Inicio</router-link>
      <router-link to="/listaPostos">Lista dos Postos</router-link>
    </nav>
    <router-view />
    <FooterView />
  </div>
</template>

<script lang="ts">
import HeaderView from "./views/HeaderView.vue";
import FooterView from "./views/FooterView.vue";

export default {
  components: {
    HeaderView,
    FooterView,
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: 0;
  width: 100%;
}

nav {
  padding: 15px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  background: #9d8888;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
  box-shadow: 0 19px 38px rgb(153, 140, 106), 0 3px 3px rgba(0, 0, 0, 0.22);
  border-radius: 15px;

  a {
    font-weight: bold;
    color: #32383d;
    margin-left: 20px;

    &.router-link-exact-active {
      color: #0a29db;
    }
  }
}
</style>
