import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterView = _resolveComponent("FooterView")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderView),
    _createElementVNode("nav", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("Página de Inicio")
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/listaPostos" }, {
        default: _withCtx(() => [
          _createTextVNode("Lista dos Postos")
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_router_view),
    _createVNode(_component_FooterView)
  ]))
}