<template>
  <div class="header borda">
    <img class="img" src="../assets/logo_ipi.png" alt="Logo Ipiranga" />
    <img class="img" src="../assets/logo_shell1.png" alt="Logo Shell" />
    <img class="img" src="../assets/logo_br1.png" alt="Logo vibra" />
    <h1>REDE RABI DE POSTOS</h1>
    <img class="img" src="../assets/logo_rabi.jpg" alt="Logo Rede Rabi" />
  </div>
</template>

<style lang="scss" scoped>
// @import '../sass/stylePadrao.scss';
.header {
  display: flex;
  //position: relative; //rola junto com a pagina
  //position: fixed top; //fixo
  //position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  margin: auto;
  background-color: rgb(190, 188, 186);
  border-bottom: 6px solid rgb(104, 70, 6);
}

.img {
  width: 70px;
  height: 50px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 25px;
}

h1 {
  font-size: 2rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: rgb(144, 105, 105);
  text-shadow: 0 0 3px #953636, 0 0 5px #deaa1a;
}
</style>
