import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CapaView from "../views/CapaView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "capa",
    component: CapaView,
  },
  {
    path: "/listaPostos",
    name: "ListaPostos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ListaPostosView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
