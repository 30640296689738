<template>
  <div class="">
    <h3>{{ msg }}</h3>
    <ul>
      <li>
        Postos com uma infraestrutura moderna e robusta.
        <!-- <a href="" target="_blank" rel="noopener"
        >link do texto</a
      > -->
      </li>
      <li>Amplo serviço de conveniência e alimentação.</li>
      <li>Pátios com amplo estácionamento para pernoite.</li>
      <li>Transporte próprio do combustível das bases para os postos.</li>
      <li>Produtos e combustíveis com a qualidade das principaís bandeiras.</li>
      <li>Preços competitivos, e pagamentos das mais diferentes formas.</li>
      <li>condições especiais para frotistas, confira conosco!</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
})
export default class MsgInicial extends Vue {
  msg!: string;
}
</script>

<style scoped lang="scss">
h3 {
  font-size: 1.5rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: rgb(144, 105, 105);
  text-shadow: 0 0 1px #953636, 0 0 1px #deaa1a;
  margin: 40px 0 0;
}

ul {
  //list-style-type: none;
  padding: 15px;
}

li {
  font-size: 1rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: rgb(27, 23, 23);
  text-shadow: 0 0 1px #953636, 0 0 1px #deaa1a;
  margin: 40px 0 0;
  //display: inline-block;
  margin: 5px 10px;
}

a {
  color: #42b983;
}
</style>
